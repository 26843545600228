<template>
  <div class="minha-conta">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>

          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-gradient">
      <div class="container text-center pt-3">
        <h3>Solicitações de regaste</h3>
        <div class="h-line" style="width:142px;"></div>
      </div>
    </div>

    <div class="container mt-5 pb-5">
      <div>
        <div class="row mb-4">
          <div class="col-md-6 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <i class="fa fa-search"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Pesquise por nome, e-mail e CPF"
                v-model="termoPesquisa"
              />
            </div>
          </div>
          <div class="col-md-6 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">Status</span>
              </div>
              <b-form-select
                v-model="statusSelecionado"
                :disabled="loadingStatus"
              >
                <option value>Todos</option>
                <option value="0">Aberto</option>
                <option value="1">Concluido</option>
              </b-form-select>
            </div>
            <small v-if="loadingStatus">Carregando...</small>
          </div>

          <div class="col-md-6 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Data de criação</span>
              </div>
              <b-form-select v-model="periodo">
                <option value>Todas</option>
                <option value="7">Ultimos 7 dias</option>
                <option value="30">Ultimos 30 dias</option>
                <option value="custom">Período customizado</option>
              </b-form-select>
            </div>
            <div class="mt-2" v-if="periodo == 'custom'">
              <div class="d-inline-block mr-2 mb-1">
                <div class="d-inline-block" style="width: 33px">De:</div>
                <input
                  type="date"
                  class="form-control form-control-sm d-inline-block w-auto"
                  v-model="periodoDataInicio"
                />
              </div>
              <div class="d-inline-block">
                <div class="d-inline-block" style="width: 33px">Até:</div>

                <input
                  type="date"
                  class="form-control form-control-sm d-inline-block w-auto"
                  v-model="periodoDataFim"
                />
              </div>
              <div class="text-danger">
                <small>{{ periodoMsgValidacao }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 order-lg-2">
          <div v-if="!loading" class="card card-body">
            <table class="table">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Cliente</th>
                  <th>Telefone</th>
                  <th>CPF</th>
                  <th>Tipo</th>
                  <th>Valor do Resgate</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="5" v-if="regates.length == 0">
                    Ainda não há pedidos de resgate para os filtros
                    selecionados.
                  </td>
                </tr>
                <tr v-for="(item, index) in regates" :key="index">
                  <td>
                    {{ formatacaoData.formatarDataHoraLocal(item.criado) }}
                  </td>
                  <td>
                    <span>{{ item.nomeCliente }}</span>
                    <div>{{ item.emailCliente }}</div>
                  </td>
                  <td>
                    <span>{{ formatacaoTelefone(item.telefoneCliente) }}</span>
                  </td>
                  <td>
                    <span>{{ helper.formatacaoCpf(item.cpfCliente) }}</span>
                  </td>
                  <td>
                    <div v-if="item.tipo == config.RESGATE_TIPO_DINHEIRO">
                      <small
                        >Chave: {{ item.dadosBancarios.tipoChavePix }}</small
                      >
                      <br />
                      <small v-if="item.dadosBancarios.cpfConta"
                        >CPF:
                        {{
                          helper.formatacaoCpf(item.dadosBancarios.cpfConta)
                        }}</small
                      >
                      <small v-else-if="item.dadosBancarios.telefonePix"
                        >Telefone:
                        {{
                          formatacaoTelefone(item.dadosBancarios.telefonePix)
                        }}</small
                      >
                      <small v-else-if="item.dadosBancarios.chaveAleatoriaPix"
                        >Aleatoria:
                        {{ item.dadosBancarios.chaveAleatoriaPix }}</small
                      >
                      <small v-else-if="item.dadosBancarios.emailPix"
                        >E-mail: {{ item.dadosBancarios.emailPix }}</small
                      >
                      <br /><small
                        >Titular: {{ item.dadosBancarios.nomeTitular }}</small
                      >
                      <br /><small
                        >Agência: {{ item.dadosBancarios.agencia }}</small
                      >
                      <br /><small
                        >Conta: {{ item.dadosBancarios.conta }}</small
                      >
                    </div>
                    <div v-else>
                      <div>Resgate em cupom, favor entrar em contato com o Wendel</div>
                      <small v-if="item.codigoCupom"
                        >Código do cupom: {{ item.codigoCupom }}</small
                      >
                    </div>
                  </td>
                  <td>
                    <span v-if="item.tipo == config.RESGATE_TIPO_DINHEIRO">{{ formatacaoMoeda(item.valorResgate) }}</span>
                    <span v-else>{{item.valorResgate}}%</span>
                  </td>
                  <td>
                    <div v-if="item.status == config.RESGATE_STATUS_ABERTO">
                      <b-badge variant="warning" pill>Aberto</b-badge>
                      <div class="mt-1">
                        <button
                          @click.prevent="
                            exibirModalComprovanteDeposito(item.idPedidoResgate)
                          "
                          class="btn btn-link pl-0"
                        >
                          Concluir este pedido
                        </button>
                      </div>
                    </div>
                    <div v-if="item.status == config.RESGATE_STATUS_CONCLUIDO">
                      <b-badge variant="success" pill>Concluído</b-badge>
                      <div v-if="item.modificadoPor">
                        Por: {{ item.modificadoPor || "-" }}
                      </div>
                      <div v-if="item.dataResgateConcluido">
                        No dia:
                        {{
                          formatacaoData.formatarDataHoraLocal(
                            item.dataResgateConcluido
                          )
                        }}
                      </div>
                      <div v-if="item.tipo == config.RESGATE_TIPO_DINHEIRO">
                        <button
                          @click.prevent="
                            exibirModalComprovanteDeposito(item.idPedidoResgate)
                          "
                          class="btn btn-link pl-0 text-left"
                        >
                          Ver/Alterar comprovante
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="text-center">
            <lottie-player
              src="/anim/mountain_bike.json"
              background="transparent"
              speed="1.7"
              style="width: 72px; height: 72px; margin:0 auto;"
              autoplay
              loop
              class="mt-3"
            ></lottie-player>
            <div>Carregando, por favor aguarde...</div>
          </div>
        </div>
      </div>
    </div>

    <ModalComprovanteDeposito
      :showModal="showModalArquivos"
      :idPedidoResgate="idPedidoResgate"
      :idUsuarioAdm="idUsuarioAdm"
      @hide="hideModalComprovanteDeposito"
    />
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import moment from "moment";
import ModalComprovanteDeposito from "../../components/MemberGetMember/ModalComprovanteDeposito";
import config from "../../config";
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import captureError from "../../helpers/captureError";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import formatacaoData from "../../helpers/formatacaoData";
import helper from "../../helpers/helper";
import formatacaoTelefone from "../../helpers/formatacaoTelefone";

export default {
  components: {
    SideNav,
    ModalComprovanteDeposito,
  },
  data() {
    return {
      config,
      loading: false,
      moment: moment,
      regates: [],
      termoPesquisa: "",
      idTimerPesquisa: undefined,
      idPedidoResgate: "",
      statusSelecionado: "",
      loadingStatus: false,
      periodo: "",
      periodoDataInicio: undefined,
      periodoDataFim: undefined,
      periodoMsgValidacao: "",
      showModalArquivos: false,
      idUsuarioAdm: undefined,
      formatacaoMoeda,
      formatacaoData,
      formatacaoTelefone,
      helper,
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (userInfo && userInfo.cargo) {
      this.idUsuarioAdm = userInfo.id;
    } else {
      this.$router.replace("/login");
    }
    this.popularPedidos();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("hide-external-buttons");
    next();
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("hide-external-buttons");

    if (config.FEATURE_FLAGS.MEMBER_GET_MEMBER) {
      next();
    } else {
      next("/login");
    }
  },
  methods: {
    exibirModalComprovanteDeposito(idPedidoResgate) {
      this.idPedidoResgate = idPedidoResgate;

      this.showModalArquivos = true;
    },
    hideModalComprovanteDeposito(status) {
      this.showModalArquivos = false;
      if (status.modificou) {
        this.popularPedidos();
      }
    },
    obterDatasFiltroPeriodo() {
      //montando as datas do período
      this.periodoMsgValidacao = "";
      var mDataInicioUtc = undefined;
      var dataInicioUtc = undefined;
      var mDataFimUtc = undefined;
      var dataFimUtc = undefined;

      if (this.periodo == "7") {
        mDataInicioUtc = moment()
          .utc()
          .startOf("day")
          .subtract(8, "days");
        dataInicioUtc = mDataInicioUtc.format("YYYY-MM-DD");
      } else if (this.periodo == "30") {
        mDataInicioUtc = moment()
          .utc()
          .startOf("day")
          .subtract(31, "days");
        dataInicioUtc = mDataInicioUtc.format("YYYY-MM-DD");
      } else if (this.periodo == "custom") {
        if (this.periodoDataInicio) {
          mDataInicioUtc = moment(this.periodoDataInicio).utc();
          dataInicioUtc = mDataInicioUtc.format("YYYY-MM-DD");
        }
        if (this.periodoDataFim) {
          mDataFimUtc = moment(this.periodoDataFim)
            .utc()
            .add(1, "days");
          dataFimUtc = mDataFimUtc.format("YYYY-MM-DD");
        }

        if (mDataInicioUtc && mDataFimUtc) {
          if (mDataFimUtc.isBefore(mDataInicioUtc)) {
            this.periodoMsgValidacao =
              "A data final deve ser maior que a inicial";
            return;
          }
        }
      }

      return { dataInicioUtc, dataFimUtc };
    },
    async popularPedidos() {
      try {
        var datas = this.obterDatasFiltroPeriodo();
        if (!datas) {
          return;
        }
        this.dataFimUtc = datas.dataFimUtc;
        this.dataInicioUtc = datas.dataInicioUtc;
        this.loading = true;
        var response = await axios.get(
          `${config.API_URLV2}/pedidoResgate?termoPesquisa=${
            this.termoPesquisa
          }&status=${this.statusSelecionado}&dataInicio=${this.dataInicioUtc ||
            ""}&dataFim=${this.dataFimUtc || ""}`
        );
        var regates = response.data.dados;

        this.regates = regates;
        this.loading = false;
      } catch (error) {
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar seu saldo. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    obterNomeBanco(codigoBanco) {
      var banco = config.LISTA_BANCOS.find((i) => i.codigo == codigoBanco);
      return banco?.nome;
    },
  },
  watch: {
    termoPesquisa: function(newValue, oldValue) {
      if (newValue != oldValue) {
        if (this.idTimerPesquisa !== undefined) {
          window.clearTimeout(this.idTimerPesquisa);
        }
        this.idTimerPesquisa = window.setTimeout(() => {
          this.popularPedidos(true);
        }, 1200);
      }
    },
    statusSelecionado: function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.popularPedidos(true);
      }
    },
    periodo: function(newValue, oldValue) {
      if (newValue != oldValue && newValue != "custom") {
        this.periodoDataInicio = undefined;
        this.periodoDataFim = undefined;
        this.popularPedidos(true);
      } else if (newValue != oldValue && newValue == "custom") {
        this.periodoDataInicio = moment().format("YYYY-MM-DD");
        this.periodoDataFim = moment().format("YYYY-MM-DD");
      }
    },
    periodoDataInicio: function(newValue, oldValue) {
      if (newValue != oldValue && this.periodo == "custom") {
        this.popularPedidos(true);
      }
    },
    periodoDataFim: function(newValue, oldValue) {
      if (newValue != oldValue && this.periodo == "custom") {
        this.popularPedidos(true);
      }
    },
  },
};
</script>
<style scoped>
.minha-conta {
  height: 100%;
  /* background-color: #f7f7f7; */
}

.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14609593837535018) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}

.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
